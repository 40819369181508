define("hintguest/components/u-int-input", ["exports", "universe-gui/components/u-int-input"], function (_exports, _uIntInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uIntInput.default;
    }
  });
});