define("hintguest/templates/manual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dONP4ex8",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"id\",\"manual\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-lg-10 col-lg-offset-1 text-justify\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"ibox\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"article-title\"],[7],[0,\"\\n                    \"],[6,\"span\"],[9,\"class\",\"text-muted\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-clock-o\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"help.manual.last-rev-date\"],null],false],[8],[0,\"\\n                    \"],[6,\"h1\"],[7],[1,[25,\"t\",[\"help.manual.title\"],null],false],[8],[0,\"\\n                \"],[8],[0,\"\\n\"],[4,\"unless\",[[25,\"t\",[\"terms.translation-not-available\"],null]],null,{\"statements\":[[0,\"                    \"],[6,\"p\"],[9,\"class\",\"alert alert-warning\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-warning\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"terms.translation-not-available\"],null],false],[8],[0,\"\\n                    \"],[6,\"p\"],[9,\"class\",\"alert alert-warning\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-warning\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"terms.language-disclaimer\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hintguest/templates/manual.hbs"
    }
  });

  _exports.default = _default;
});