define("hintguest/components/avataaars-generator", ["exports", "universe-gui/components/avataaars-generator"], function (_exports, _avataaarsGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _avataaarsGenerator.default;
    }
  });
});