define("hintguest/components/custom-form", ["exports", "universe-gui/components/custom-form"], function (_exports, _customForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _customForm.default;
    }
  });
});