define("hintguest/components/u-alert", ["exports", "universe-gui/components/u-alert"], function (_exports, _uAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uAlert.default;
    }
  });
});