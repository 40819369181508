define("hintguest/models/employee", ["exports", "universe-gui/models/employ"], function (_exports, _employ) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _employ.default;
    }
  });
});