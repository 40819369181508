define("hintguest/templates/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pBkYMhVj",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.settings\"],null],\"cogs\"]]],false],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"page-body\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-lg-10 col-lg-offset-1\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"title\",\"titleIcon\",\"hideTools\"],[[25,\"t\",[\"models.company.country\"],null],\"language\",true]],{\"statements\":[[0,\"                \"],[6,\"div\"],[9,\"class\",\"p-lg\"],[7],[0,\"\\n                    \"],[1,[18,\"pu-language-select\"],false],[0,\"\\n                \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hintguest/templates/settings.hbs"
    }
  });

  _exports.default = _default;
});