define("hintguest/controllers/hints/restaurants/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      next: function next(selected) {
        this.set("model." + this.get("model.next"), selected);
        var next = 1 + this.get("model.codes").indexOf(this.get("model.next"));

        if (this.get("model.codes.length") === next) {
          this.transitionToRoute("hints.hint");
        } else {
          this.set("model.next", this.get("model.codes")[next]);
        }
      }
    }
  });

  _exports.default = _default;
});