define("hintguest/templates/welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NuMe76RA",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"jumbotron text-center\"],[7],[0,\"\\n  \"],[6,\"h1\"],[7],[0,\"Welcome!\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"We will help you find the best experience.\"],[8],[0,\"\\n    \"],[6,\"p\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"hints.restaurants.search.purpose\"],[[\"class\"],[\"btn btn-primary btn-lg\"]],{\"statements\":[[0,\"            \"],[1,[25,\"t\",[\"restaurants.title\"],null],false],[0,\" \"],[6,\"i\"],[9,\"class\",\"fa fa-search\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hintguest/templates/welcome.hbs"
    }
  });

  _exports.default = _default;
});