define("hintguest/routes/users/user", ["exports", "universe-gui/routes/users/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _user.default;
    }
  });
});