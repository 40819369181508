define("hintguest/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SyQI51yM",
    "block": "{\"symbols\":[],\"statements\":[[6,\"section\"],[9,\"class\",\"loading-page\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"watch-face\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"circle\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"circle\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"circle\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"circle\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"circle\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"circle\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hintguest/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});