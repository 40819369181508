define("hintguest/templates/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v9WKWPfg",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h1\"],[7],[0,\"Hint Guest\"],[8],[0,\"\\n\"],[6,\"h2\"],[7],[0,\"Restaurant hints\"],[8],[0,\"\\n\"],[6,\"p\"],[7],[0,\"Many of us have limited time on vacation and that also means limited number of delicious meals to eat, so it’s important to make every meal count.\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hintguest/templates/about.hbs"
    }
  });

  _exports.default = _default;
});