define("hintguest/transforms/localdate", ["exports", "universe-gui/transforms/localdate"], function (_exports, _localdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _localdate.default;
    }
  });
});