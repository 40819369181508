define("hintguest/router", ["exports", "hintguest/config/environment", "universe-gui/router"], function (_exports, _environment, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    (0, _router.default)(this);
    this.route('welcome');
    this.route('about');
    this.route('hints', function () {
      this.route('hint');
      this.route('restaurants', function () {
        this.route('search', function () {
          this.route('purpose');
          this.route('meal');
        });
        this.route('restaurant');
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});