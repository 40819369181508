define("hintguest/templates/recover-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "leG0Sc7f",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-md-4 col-md-offset-4\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"title\",\"hideTools\"],[[25,\"t\",[\"pages.recoverPassword\"],null],true]],{\"statements\":[[0,\"            \"],[6,\"br\"],[7],[8],[0,\"\\n            \"],[6,\"form\"],[9,\"class\",\"m-t\"],[9,\"role\",\"form\"],[3,\"action\",[[19,0,[]],\"recoverPassword\",[20,[\"email\"]]],[[\"on\"],[\"submit\"]]],[7],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                    \"],[1,[25,\"input\",null,[[\"value\",\"type\",\"class\",\"placeholder\",\"required\"],[[20,[\"email\"]],\"email\",\"form-control\",[25,\"t\",[\"user.new.email-example\"],null],\"true\"]]],false],[0,\"\\n                \"],[8],[0,\"\\n\\n                \"],[1,[25,\"pu-button\",null,[[\"lockButton\",\"icon\",\"text\"],[[20,[\"disableBtn\"]],\"floppy-o\",[25,\"t\",[\"navigation.confirm\"],null]]]],false],[0,\"\\n            \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hintguest/templates/recover-password.hbs"
    }
  });

  _exports.default = _default;
});