define("hintguest/components/user-create", ["exports", "universe-gui/components/user-create"], function (_exports, _userCreate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _userCreate.default;
    }
  });
});