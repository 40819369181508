define("hintguest/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fXzEKNoU",
    "block": "{\"symbols\":[],\"statements\":[[6,\"section\"],[9,\"id\",\"login\"],[7],[0,\"\\n    \"],[6,\"section\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-md-6 col-md-offset-3\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"title\",\"hideTools\"],[[25,\"t\",[\"login.subtitle\"],null],true]],{\"statements\":[[0,\"                \"],[6,\"br\"],[7],[8],[0,\"\\n                \"],[1,[18,\"pu-login-form\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hintguest/templates/login.hbs"
    }
  });

  _exports.default = _default;
});