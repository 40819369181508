define("hintguest/translations/es", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "about": {
      "contacts": "Contactos"
    },
    "account": {
      "personEditSubtitle": "Actualice sus datos personales.",
      "securityEditSubtitle": "Cambiar la información de inicio de sesión.",
      "settingsSubtitle": "Personaliza el sistema como quieras.",
      "softwareUpdated": "Hay una actualización disponible - {module} versión: {version}.<br> <b>Haga clic aquí para actualizar!</b>",
      "title": "Cuenta"
    },
    "application": {
      "loading": "Cargando"
    },
    "avatar": {
      "accessories": {
        "kurt": "Kurt",
        "prescription1": "Prescripción 1",
        "prescription2": "Prescripción 2",
        "round": "Ronda",
        "sunglasses": "Gafas de Sol",
        "title": "Accesorios",
        "wayfarers": "Wayfarer"
      },
      "clothes": {
        "blazerShirt": "Chaqueta camiseta",
        "blazerSweater": "Chaqueta suéter",
        "collarSweater": "Suéter de cuello",
        "graphicShirt": "Camiseta Gráfica",
        "hoodie": "Sudadera",
        "overall": "Mono",
        "shirtCrewNeck": "Camiseta de cuello redondo",
        "shirtScoopNeck": "Camiseta de cuello ovalado",
        "shirtVNeck": "Camiseta de cuello en V",
        "title": "Ropa"
      },
      "colorFabric": {
        "black": "Negro",
        "blue1": "Azul 1",
        "blue2": "Azul 2",
        "blue3": "Azul 3",
        "gray1": "Gris 1",
        "gray2": "Gris 2",
        "heather": "Lavanda",
        "pastelBlue": "Azul Pastel",
        "pastelGreen": "Verde Pastel",
        "pastelOrange": "Naranja Pastel",
        "pastelRed": "Rojo Pastel",
        "pastelYellow": "Amarillo Pastel",
        "pink": "Rosa",
        "red": "Rojo",
        "title": "Colores del tejido",
        "white": "Blanco"
      },
      "eyebrow": {
        "angry": "Enfadado",
        "angryNatural": "Enfadado Natural",
        "default": "Por defecto",
        "defaultNatural": "Natural por defecto",
        "flatNatural": "Liso Natural",
        "raisedExcited": "Emocionado",
        "raisedExcitedNatural": "Emocionado Natural",
        "sadConcerned": "Triste",
        "sadConcernedNatural": "Triste Natural",
        "title": "Cejas",
        "unibrowNatural": "Unicejo",
        "upDown": "Arriba Abajo",
        "upDownNatural": "Arriba Abajo Natural"
      },
      "eyes": {
        "close": "Cerrados",
        "cry": "llorar",
        "default": "Por defecto",
        "dizzy": "Tonto",
        "eyeRoll": "Rolo de Olhos",
        "happy": "Feliz",
        "hearts": "Corazones",
        "side": "Lado",
        "squint": "Estrabismo",
        "surprised": "Sorprendido",
        "title": "Ojos",
        "wink": "Guiño",
        "winkWacky": "Guiño chiflado"
      },
      "hatColor": {
        "blue1": "Azul 1",
        "blue2": "Azul 2",
        "blue3": "Azul 3",
        "gray1": "Gris 1",
        "gray2": "Gris 2",
        "heather": "Lavanda",
        "pastelBlue": "Azul Pastel",
        "pastelGreen": "Verde Pastel",
        "pastelOrange": "Naranja Pastel",
        "pastelRed": "Rojo Pastel",
        "pastelYellow": "Amarillo Pastel",
        "pink": "Rosa",
        "red": "Rojo",
        "title": "Color del Sombrero",
        "white": "Blanco"
      },
      "mouth": {
        "concerned": "Preocupado",
        "default": "Por defecto",
        "disbelief": "Incrédulo",
        "eating": "Comiendo",
        "grimace": "Careta",
        "sad": "Triste",
        "screamOpen": "Miedo",
        "serious": "Serio",
        "smile": "Sonrisa",
        "title": "Boca",
        "tongue": "Lengua",
        "twinkle": "Parpadeo",
        "vomit": "Vómito"
      },
      "skin": {
        "black": "Negra",
        "brown": "Marrón",
        "darkBrown": "Marrón oscuro",
        "light": "Clara",
        "pale": "Pálida",
        "tanned": "Morena",
        "title": "Piel",
        "yellow": "Amarilla"
      },
      "top": {
        "ShortHairShortFlat": "Pelo corto liso",
        "eyepatch": "Parche ojo",
        "hat": "Sombrero",
        "hijab": "Hijab",
        "longHairBigHair": "Pelo Largo",
        "longHairBob": "Pelo Largo Bob",
        "longHairBun": "Moño de pelo largo",
        "longHairCurly": "Pelo Largo Rizado",
        "longHairCurvy": "Pelo Largo Ondulado",
        "longHairDreads": "Pelo Largo Desastroso",
        "longHairFrida": "Pelo Largo Frida",
        "longHairFro": "Pelo Largo Fro",
        "longHairFroBand": "Pelo Largo Fro con cinta",
        "longHairMiaWallace": "Pelo largo Mia Wallace",
        "longHairNotTooLong": "Pelo no muy largo",
        "longHairShavedSides": "Pelo largo con laterales rapados",
        "longHairStraight1": "Pelo largo raya recta",
        "longHairStraight2": "Pelo largo raya recta 2",
        "longHairStraightStrand": "Pelo largo raya zigzag",
        "shortHairDreads1": "Pelo corto temible 1",
        "shortHairDreads2": "Pelo corto temible 2",
        "shortHairFrizzle": "Pelo corto rizado",
        "shortHairShaggyMullet": "Pelo corto despeinado",
        "shortHairShortCurly": "Pelo corto encaracolado",
        "shortHairShortRound": "Pelo Corto",
        "shortHairShortWaved": "Pelo Corto Ondulado",
        "shortHairSides": "Pelo corto a los lados",
        "shortHairTheCaesar": "Pelo corto César",
        "shortHairTheCaesarSidePart": "Pelo Corto César Parte Lateral",
        "title": "Cabeza",
        "turban": "Turbante",
        "winterHat1": "Sombrero de Invierno 1",
        "winterHat2": "Sombrero de Invierno 2",
        "winterHat3": "Sombrero de Invierno 3",
        "winterHat4": "Sombrero de Invierno 4"
      }
    },
    "bugReports": {
      "bugReport": {
        "message": "Mensaje",
        "subject": "Sujeto",
        "title": "Reportar error"
      },
      "title": "Reportar errores"
    },
    "companies": {
      "details": {
        "disabled": "Esta empresa no tiene un plan associado, para iniciar un plan de prueba por favor contáctenos por correo electrónico!"
      },
      "new": {
        "title": "Nueva Empresa"
      }
    },
    "crud": {
      "associate": "Asociar",
      "cancel": "Cancelar",
      "close": "Cerca",
      "create": "Crear",
      "createAndAdd": "Crear y agregar nuevos",
      "createSuccess": "Registro con éxito para",
      "created": "Creado",
      "delete": "Borrar",
      "details": "Detalhes",
      "register": "Registro",
      "registered": "Registrado",
      "revert": "Revertir",
      "save": "Guardar",
      "saved": "Guardado",
      "schedule": "Programar",
      "update": "Actualizar",
      "updated": "Actualizado"
    },
    "dayWeek": {
      "0": "Domingo",
      "1": "Lunes",
      "2": "Martes",
      "3": "Miércoles",
      "4": "Jueves",
      "5": "Viernes",
      "6": "Sábado"
    },
    "enums": {
      "chronos": {
        "date": "fecha",
        "dateUpper": "Fecha",
        "day": "día",
        "dayUpper": "días",
        "days": "días",
        "hour": "hora",
        "hourUpper": "Hora",
        "hours": "horas",
        "hoursUpper": "Horas",
        "month": "mes",
        "monthUpper": "Mes",
        "months": "meses",
        "today": "hoy",
        "todayUpper": "Hoy",
        "waitingTime": "tiempo de espera",
        "waitingTimeUpper": "Tiempo de Espera",
        "week": "semana",
        "weekUpper": "Semana",
        "year": "año",
        "yearUpper": "Año",
        "years": "años"
      },
      "countries": {
        "AD": "Andorra",
        "AE": "Emiratos Arabes",
        "AF": "Afganistán",
        "AG": "Antigua y Barbuda",
        "AI": "Anguilla",
        "AL": "Albania",
        "AM": "Armenia",
        "AN": "Países Bajos Antillas",
        "AO": "Angola",
        "AQ": "Antártida",
        "AR": "Argentina",
        "AS": "Samoa Americana",
        "AT": "Austria",
        "AU": "Australia",
        "AW": "Aruba",
        "AZ": "Azerbaiyán",
        "BA": "Bosnia Herzegovina",
        "BB": "Barbados",
        "BD": "Bangladesh",
        "BE": "Bélgica",
        "BF": "Burkina Faso",
        "BG": "Bulgaria",
        "BH": "Baréin",
        "BI": "Burundi",
        "BJ": "Benín",
        "BM": "Bermudas",
        "BN": "Brunéi Darussalam",
        "BO": "Bolivia",
        "BR": "Brasil",
        "BS": "Bahamas",
        "BT": "Bután",
        "BV": "Isla Bouvet",
        "BW": "Botsuana",
        "BY": "Bielorusia",
        "BZ": "Belice",
        "CA": "Canadá",
        "CC": "Islas Cocos (Keeling)",
        "CD": "Congo, República Democrática del",
        "CF": "República Centroafricana",
        "CG": "Congo",
        "CH": "Suiza",
        "CI": "Côte d’Ivoire",
        "CK": "Islas Cook",
        "CL": "Chile",
        "CM": "Camerún",
        "CN": "China",
        "CO": "Colombia",
        "CR": "Costa Rica",
        "CU": "Cuba",
        "CV": "Cabo Verde",
        "CX": "Isla de Navidad",
        "CY": "Chipre",
        "CZ": "República Checa",
        "DE": "Alemania",
        "DJ": "Djibouti",
        "DK": "Dinamarca",
        "DM": "Dominica",
        "DO": "República Dominicana",
        "DZ": "Argelia",
        "EC": "Ecuador",
        "EE": "Estonia",
        "EG": "Egipto",
        "EH": "Sáhara Occidental",
        "ER": "Eritrea",
        "ES": "España",
        "ET": "Etiopía",
        "FI": "Finlandia",
        "FJ": "Fiyi",
        "FK": "Falkland, Islas (Malvinas)",
        "FM": "Estados Federados de Micronesia",
        "FO": "Islas Feroe",
        "FR": "Francia",
        "GA": "Gabón",
        "GB": "Reino Unido",
        "GD": "Granada",
        "GE": "Georgia",
        "GF": "Guayana Francesa",
        "GH": "Gana",
        "GI": "Gibraltar",
        "GL": "Groenlandia",
        "GM": "Gambia",
        "GN": "Guinea",
        "GP": "Guadalupe",
        "GQ": "Guinea Ecuatorial",
        "GR": "Grecia",
        "GS": "Islas Georgias del Sur y Sandwich del Sur",
        "GT": "Guatemala",
        "GU": "Guam",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HK": "Hong Kong",
        "HM": "Islas Heard y McDonald",
        "HN": "Honduras",
        "HR": "Croacia",
        "HT": "Xls",
        "HU": "Hungría",
        "ID": "Indonesia",
        "IE": "Irlanda",
        "IL": "Israel",
        "IN": "India",
        "IO": "Territorio Britanico del oceano Indico",
        "IQ": "Irak",
        "IR": "Irán (República Islámica de)",
        "IS": "Islandia",
        "IT": "Italia",
        "JM": "Jamaica",
        "JO": "Jordania",
        "JP": "Japón",
        "KE": "Kenia",
        "KG": "Kirguistán",
        "KH": "Camboya",
        "KI": "Kiribati",
        "KM": "Comoras",
        "KN": "San Cristóbal y Nieves",
        "KP": "República Popular Democrática de Corea",
        "KR": "Corea, República de",
        "KW": "Kuwait",
        "KY": "Islas Caimán",
        "KZ": "Kazakstán",
        "LA": "República Democrática Popular de Laos",
        "LB": "Líbano",
        "LC": "Santa Lucía",
        "LI": "Liechtenstein",
        "LK": "Sri Lanka",
        "LR": "Liberia",
        "LS": "Lesoto",
        "LT": "Lituania",
        "LU": "Luxemburgo",
        "LV": "Letonia",
        "LY": "Libia",
        "MA": "Marruecos",
        "MC": "Mónaco",
        "MD": "República de Moldova",
        "MG": "Madagascar",
        "MH": "Islas Marshall",
        "MK": "Macedonia, República de",
        "ML": "Malí",
        "MM": "Myanmar",
        "MN": "Mongolia",
        "MO": "Macao",
        "MP": "Islas Marianas del Norte",
        "MQ": "Martinica",
        "MR": "Mauritania",
        "MS": "Montserrat",
        "MT": "Malta",
        "MU": "Mauricio",
        "MV": "Maldivas",
        "MW": "Malawi",
        "MX": "México",
        "MY": "Malasia",
        "MZ": "Mozambique",
        "NA": "Austria",
        "NC": "Nueva Caledonia",
        "NE": "Niger",
        "NF": "Isla de Norfolk",
        "NG": "Nigeria",
        "NI": "Nicaragua",
        "NL": "Países Bajos",
        "NO": "Noruega",
        "NP": "Nepal",
        "NR": "Nauru",
        "NU": "Niue",
        "NZ": "Nueva Zelanda",
        "OM": "Omán",
        "PA": "Panamá",
        "PE": "Perú",
        "PF": "Polinesia Francesa",
        "PG": "Papúa Nueva Guinea",
        "PH": "Filipinas",
        "PK": "Pakistán",
        "PL": "Polonia",
        "PM": "San Pedro y Miquelón",
        "PN": "Islas Pitcairn",
        "PR": "Puerto Rico",
        "PS": "Territorio Ocupado Palestino",
        "PT": "Portugal",
        "PW": "Palau",
        "PY": "Paraguay",
        "QA": "Catar",
        "RE": "Reunión",
        "RO": "Rumanía",
        "RU": "Rusia",
        "RW": "Ruanda",
        "SA": "Arabia Saudí",
        "SB": "Salomón, Islas",
        "SC": "Seychelles",
        "SD": "Sudán",
        "SE": "Suecia",
        "SG": "Singapur",
        "SH": "Santa Helena",
        "SI": "Eslovenia",
        "SJ": "Svalbard y Jan Mayen",
        "SK": "Eslovaquia",
        "SL": "Sierra Leona",
        "SM": "San Marino",
        "SN": "Senegal",
        "SO": "Somalia",
        "SR": "Surinam",
        "ST": "Santo Tomé y Príncipe",
        "SV": "El Salvador",
        "SY": "República Árabe Siria",
        "SZ": "Swazilandia",
        "TC": "Islas Turcas y Caicos",
        "TD": "Chad",
        "TF": "Territorios Australes Franceses",
        "TG": "Togo",
        "TH": "Tailandia",
        "TJ": "Tayikistán",
        "TK": "Tokelau",
        "TM": "Turkmenistán",
        "TN": "Túnez",
        "TO": "Tonga",
        "TP": "Timor Oriental",
        "TR": "Turquía",
        "TT": "Trinidad y Tobago",
        "TV": "Tuvalu",
        "TW": "Taiwán",
        "TZ": "Tanzania, República Unida de",
        "UA": "Ucrania",
        "UG": "Uganda",
        "UM": "Islas Ultramarinas de Estados Unidos",
        "US": "Estados Unidos",
        "UY": "Uruguay",
        "UZ": "Uzbekistán",
        "VA": "Santa Sede (Ciudad Estado del Vaticano)",
        "VC": "San Vicente y las Granadinas",
        "VE": "Venezuela",
        "VG": "Islas Vírgenes Británicas",
        "VI": "Islas Vírgenes de los Estados Unidos",
        "VN": "Vietnam",
        "VU": "Vanuatu",
        "WF": "Wallis y Futuna",
        "WS": "Samoa",
        "YE": "Yemen",
        "YT": "Mayotte",
        "YU": "Yugoslavia",
        "ZA": "Sudáfrica",
        "ZM": "Zambia",
        "ZW": "Zimbabue"
      },
      "currencies": {
        "$": "Dólar",
        "EUR": "Euro",
        "GBP": "Libra",
        "R$": "Real"
      },
      "mobileCountries": {
        "ES": "España +34",
        "PT": "Portugal +351"
      },
      "sex": {
        "female": {
          "initial": "F",
          "name": "Femenino"
        },
        "male": {
          "initial": "M",
          "name": "Masculino"
        }
      }
    },
    "error": {
      "adapter": {
        "forbidden": "No tienes permisos para hacer esto"
      },
      "noInternet": "No podemos encontrar una conexión a Internet",
      "noPermission": "¡No tienes permisos para acceder a esta página!",
      "systemDown": "El sistema está actualmente inactivo",
      "unexistingBreed": "Debe seleccionar una raza de la lista"
    },
    "footer": {
      "rights": "Todos los derechos reservados",
      "toggle": "Cambio vista"
    },
    "form": {
      "administrative": "Administrativo",
      "chooseOne": " -- elige uno --",
      "extra": "Extra",
      "general": "General",
      "health": "Salud",
      "look": "Mira",
      "required": "Este campo es requerido"
    },
    "getChrome": {
      "message": "Esta aplicación sólo funciona con Google Chrome."
    },
    "header": {
      "logout": "Cerrar sesión"
    },
    "help": {
      "faq": {
        "last-rev-date": "29 de junio de 2016",
        "link": "FAQ",
        "title": "Preguntas más frecuentes"
      },
      "manual": {
        "last-rev-date": "29 de junio de 2016",
        "link": "Manual",
        "title": "Manual"
      },
      "title": "Ayuda"
    },
    "icons": {
      "bug": "bug",
      "company": "building-o",
      "employee": "users",
      "image": "image",
      "user": "user",
      "worker": "user"
    },
    "index": {
      "addInternments": {
        "description": "Crear o Buscar cliente, llenar los detalles de los animales y el pasante.",
        "title": "Internos"
      },
      "associateCollaborators": {
        "description": "Asociar colaboradores con diferentes funciones.",
        "title": "Colaboradores"
      },
      "comments": "Si tiene alguna duda o comentario, póngase en contacto con",
      "createAccount": {
        "description": "Rellene sus datos y registre su cuenta.",
        "title": "Crear una cuenta"
      },
      "createHospital": {
        "description": "Añadir los detalles del hospital y crear.",
        "title": "Registro Hospital"
      },
      "welcome.subtitle": "Por {corp}"
    },
    "intl": {
      "attention": "¡Atención!",
      "close": "¿Seguro que quieres cerrar?",
      "discharge": "¿Estás seguro de que quieres programar una descarga?",
      "emptyString": "--",
      "error": "Error",
      "finish": "¿Está seguro de que desea terminar?",
      "information": "info",
      "my": "Mi",
      "noContent": "No existen",
      "or": "o",
      "pause": "Tem a certeza que pretende pausar a consulta?",
      "remove": "¿Estás seguro de que desea eliminar?",
      "revert": "¿Está seguro de que desea revertir?",
      "revertDischarge": "¿Está seguro de que desea revertir la descarga?",
      "showLess": "Mostrar menos...",
      "showMore": "Mostrar más...",
      "unsavedChanges": "Al cancelar todos los cambios no guardados se perderán. ¿Desea continuar?"
    },
    "language-select": {
      "language": {
        "en": "English",
        "es": "Español",
        "es-mx": "Español Mexicano",
        "pt": "Português",
        "pt-br": "Português do Brasil"
      }
    },
    "login": {
      "forgot": "Se te olvidó tu contraseña?",
      "invalidPassword": "Contraseña invalida",
      "missingAccount": "¿No tiene una cuenta?",
      "submit": "Iniciar sesión",
      "subtitle": "Iniciar sesión en Pet Universal",
      "title": "Iniciar sesión",
      "unknownUser": "Email desconocido"
    },
    "menu": {
      "new": "Nuevo"
    },
    "models": {
      "company": {
        "child": "Sub-unidade",
        "children": "Sub-unidades",
        "country": "País",
        "email": "Email",
        "entity": "Empresa",
        "entityPlural": "Empresas",
        "facebook": "Facebook",
        "logo": "Logo",
        "name": "Nome",
        "notes": "Recados",
        "parent": "Unidade mãe",
        "phone": "Telefone",
        "street": "Morada",
        "town": "Cidade",
        "vatNumber": "CIF",
        "zip": "Cod. Postal"
      },
      "employee": {
        "entity": "Empleado",
        "entityPlural": "Empleados",
        "profile": "Perfil"
      },
      "person": {
        "country": "País",
        "email": "Email",
        "entity": "Persona",
        "entityPlural": "Gente",
        "mobilePhone": "Móvil",
        "name": "Nombre",
        "nif": "UTR",
        "phone": "Teléfono",
        "street": "Dirección",
        "town": "Pueblo",
        "zip": "Código postal"
      },
      "role": {
        "administrator": "Adminstrador",
        "employee": "Empleado",
        "unknown": "Desconocido"
      },
      "user": {
        "email": "Email",
        "entity": "Usuario",
        "entityPlural": "Usuarios",
        "name": "Nombre",
        "nif": "UTR",
        "password": "Contraseña"
      }
    },
    "navigation": {
      "addAsPatient": "Añadir como paciente",
      "back": "Atrás",
      "confirm": "Confirmar"
    },
    "organizations": {
      "new": {
        "title": "Nueva organizacion"
      },
      "organization": {
        "avatar": "Avatar",
        "avatars": "Avatars",
        "businessHours": "Horario",
        "children": "Sub-unidades",
        "company": "Empresa",
        "country": "País",
        "created": "Creado",
        "details": {
          "disabled": "Esta entidad no tiene un plan asociado."
        },
        "email": "Email",
        "enable": "Autorizar",
        "facebook": "Facebook",
        "index": {
          "afterNoon": "Buenas tardes",
          "hi": "Hola",
          "morning": "Buenos días",
          "night": "Buenas noches"
        },
        "name": "Nombre",
        "notes": "Notas",
        "parent": "Unidad Madre",
        "phone": "Teléfono",
        "plan": "Plan",
        "street": "Dirección",
        "title": "Organización",
        "town": "Ciudad",
        "zip": "Código Postal"
      },
      "title": "Organizaciones"
    },
    "pages": {
      "account": "Cuenta",
      "companies": "Empresas",
      "company": "Empresa",
      "edit": "Editar",
      "employees": "Empleados",
      "new": "Crear",
      "organizations": "Organizaciones",
      "recoverPassword": "Recuperación de contraseña",
      "security": "Seguridad",
      "settings": "Configuración"
    },
    "systemDown1": "El sistema estará inactivo por mantenimiento a las",
    "systemDown2": "durante aproximadamente dos horas.",
    "terms": {
      "cookies": {
        "link": "Cookies",
        "title": "Cookies"
      },
      "general": {
        "link": "Términos",
        "title": "Términos y Condiciones"
      },
      "language-disclaimer": "Este acuerdo fue escrito en portugués (pt-PT). En la medida en que cualquier versión traducida de este acuerdo entra en conflicto con la versión portuguesa, la versión portuguesa controla",
      "last-rev-date": "1 de enero de 2016",
      "last-rev-date-desc": "Fecha de la última revisión",
      "privacy": {
        "link": "Privacidad",
        "title": "Política de privacidad"
      },
      "translation-not-available": "Esta página no está disponible en español, por ahora sólo ofrecemos una versión en portugués."
    },
    "unexpectedError": "Error inesperado. Espere unos minutos y vuelva a intentarlo. Si el error persiste, póngase en contacto con Pet Universal.",
    "user": {
      "edit": {
        "title": "Información de usuario"
      },
      "new": {
        "agree-terms": "Al hacer clic en Inicio de sesión, acepta nuestros términos y ha leído nuestra política de privacidad",
        "confirmPassword": "Confirmar contraseña",
        "email-example": "tu.nombre@example.com",
        "name-example": "Nombre de usuario",
        "password-example": "contraseña",
        "passwordsDontMatch": "Las contraseñas no coinciden",
        "title": "Regístrate",
        "validationEmailSent": "Gracias por registrarse, vaya a su correo electrónico y valide su cuenta"
      },
      "recoverPassword.success": "Un email fue enviado a usted con su nueva contraseña"
    }
  };
  _exports.default = _default;
});