define("hintguest/components/ember-html-table-to-excel-button", ["exports", "ember-html-table-to-excel/components/ember-html-table-to-excel-button"], function (_exports, _emberHtmlTableToExcelButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberHtmlTableToExcelButton.default;
    }
  });
});