define("hintguest/routes/hints/restaurants/search/purpose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      select: function select(selected) {
        this.set("model.purpose", selected);
        this.transitionTo("hints.restaurants.search.meal");
      }
    }
  });

  _exports.default = _default;
});