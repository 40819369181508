define("hintguest/templates/hints/hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0PBFMWB3",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"p-sm\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"hint-image\"],[7],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"h1\"],[7],[0,\"Restaurante do ze\"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"well\"],[7],[0,\"\\n    Tipical portuguese Food\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hintguest/templates/hints/hint.hbs"
    }
  });

  _exports.default = _default;
});