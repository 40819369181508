define("hintguest/templates/hints/restaurants/search/purpose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iUl2ZEZD",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-6 clickable animated pulse\"],[3,\"action\",[[19,0,[]],\"biz\"]],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"widget navy-bg p-lg text-center\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"m-b-md\"],[7],[0,\"\\n                \"],[6,\"i\"],[9,\"class\",\"fa fa-briefcase fa-4x\"],[7],[8],[0,\"\\n                \"],[6,\"h1\"],[9,\"class\",\"m-xs\"],[7],[0,\" \"],[8],[0,\"\\n                \"],[6,\"h3\"],[9,\"class\",\"font-bold no-margins\"],[7],[0,\"\\n                    \"],[1,[25,\"t\",[\"travel.purpose.business\"],null],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-6 clickable animated pulse\"],[3,\"action\",[[19,0,[]],\"leasure\"]],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"widget navy-bg p-lg text-center\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"m-b-md\"],[7],[0,\"\\n                \"],[6,\"i\"],[9,\"class\",\"fa fa-life-ring fa-4x\"],[7],[8],[0,\"\\n                \"],[6,\"h1\"],[9,\"class\",\"m-xs\"],[7],[0,\" \"],[8],[0,\"\\n                \"],[6,\"h3\"],[9,\"class\",\"font-bold no-margins\"],[7],[0,\"\\n                    \"],[1,[25,\"t\",[\"travel.purpose.leasure\"],null],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hintguest/templates/hints/restaurants/search/purpose.hbs"
    }
  });

  _exports.default = _default;
});