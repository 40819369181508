define("hintguest/routes/hints/restaurants/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return {
        next: "purpose",
        codes: ["purpose", "meal", "party", "mood", "cuisine", "protein", "intolerance", "distance", "location", "price"],
        options: {
          purpose: [{
            title: "Business",
            icon: "briefcase",
            code: "business"
          }, {
            title: "Leasure",
            icon: "sun-o",
            code: "leasure"
          }],
          meal: [{
            title: "Breakfast",
            icon: "apple",
            code: "breakfast"
          }, {
            title: "Brunch",
            icon: "lemon-o",
            code: "brunch"
          }, {
            title: "Lunch",
            icon: "cutlery",
            code: "lunch"
          }, {
            title: "Dinner",
            icon: "spoon",
            code: "dinner"
          }],
          party: [{
            title: "Solo",
            icon: "user",
            code: "solo"
          }, {
            title: "Couple",
            icon: "male",
            icon2: "female",
            code: "couple"
          }, {
            title: "children",
            icon: "users",
            code: "children"
          }, {
            title: "Group",
            icon: "users",
            code: "group"
          }],
          mood: [{
            title: "Formal",
            icon: "black-tie",
            code: "formal"
          }, {
            title: "Casual",
            icon: "coffee",
            code: "couple"
          }, {
            title: "Theme",
            icon: "glass",
            code: "children"
          }, {
            title: "Entertainment",
            icon: "music",
            code: "entertainment"
          }],
          cuisine: [{
            title: "Portuguese",
            icon: "map-marker",
            code: "portuguese"
          }, {
            title: "Mexican",
            icon: "globe",
            code: "mexican"
          }, {
            title: "Italian",
            icon: "info",
            code: "italian"
          }, {
            title: "Asian",
            icon: "plane",
            code: "asian"
          }, {
            title: "Chef",
            icon: "trophy",
            code: "chef"
          }, {
            title: "Michelin",
            icon: "star",
            code: "michelin"
          }],
          protein: [{
            title: "Meet",
            icon: "cutlery",
            code: "meet"
          }, {
            title: "Fish",
            icon: "cutlery",
            code: "fish"
          }, {
            title: "Vegetarian",
            icon: "cutlery",
            code: "vegetarian"
          }, {
            title: "Vegan",
            icon: "cutlery",
            code: "vegan"
          }],
          intolerance: [{
            title: "Gluten free",
            icon: "exclamation-triangle ",
            code: "gluten"
          }, {
            title: "Lactose Free",
            icon: "exclamation-triangle ",
            code: "lactose"
          }, {
            title: "Seefood Free",
            icon: "exclamation-triangle ",
            code: "seefood"
          }, {
            title: "Some other",
            icon: "exclamation-triangle ",
            code: "intolerances"
          }],
          distance: [{
            title: "Walking",
            icon: "blind",
            code: "walking"
          }, {
            title: "Transportation",
            icon: "car",
            code: "transportation"
          }],
          price: [{
            title: "€",
            icon: "usd",
            code: "price1"
          }, {
            title: "€€",
            icon: "money",
            code: "price2"
          }, {
            title: "€€€",
            icon: "credit-card",
            code: "price3"
          }, {
            title: "€€€€",
            icon: "credit-card-alt",
            code: "price4"
          }],
          location: [{
            title: "City center",
            icon: "dot-circle-o",
            code: "citycenter"
          }, {
            title: "Historic Zone",
            icon: "university",
            code: "historiczone"
          }, {
            title: "Beach",
            icon: "life-ring",
            code: "beach"
          }, {
            title: "Anywhere",
            icon: "map-signs",
            code: "anywhere"
          }]
        }
      };
    }
  });

  _exports.default = _default;
});