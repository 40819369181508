define("hintguest/helpers/pu-chronos-date-time2", ["exports", "universe-gui/helpers/pu-chronos-date-time2"], function (_exports, _puChronosDateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _puChronosDateTime.default;
    }
  });
});