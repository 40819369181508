define("hintguest/components/u-button-submit", ["exports", "universe-gui/components/u-button-submit"], function (_exports, _uButtonSubmit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uButtonSubmit.default;
    }
  });
});