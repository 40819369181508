define("hintguest/routes/cookies", ["exports", "universe-gui/routes/cookies"], function (_exports, _cookies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _cookies.default;
    }
  });
});