define("hintguest/components/pu-page-left", ["exports", "universe-gui/components/pu-page-left"], function (_exports, _puPageLeft) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _puPageLeft.default;
    }
  });
});