define("hintguest/templates/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4u89v7tW",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"animated fadeInDown\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-lg-6 col-lg-offset-3\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n                \"],[1,[25,\"user-create\",null,[[\"transitionTo\"],[\"transitionTo\"]]],false],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hintguest/templates/users/new.hbs"
    }
  });

  _exports.default = _default;
});