define("hintguest/helpers/field-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fieldValue = fieldValue;
  _exports.default = void 0;

  function fieldValue() {}

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      return params[0] && params[1] ? params[0][params[1]] : null;
    }
  });

  _exports.default = _default;
});