define("hintguest/initializers/transforms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    This initializer is here to keep backwards compatibility with code depending
    on the `transforms` initializer (before Ember Data was an addon).
  
    Should be removed for Ember Data 3.x
  */
  var _default = {
    name: 'transforms',
    before: 'store',
    initialize: function initialize() {}
  };
  _exports.default = _default;
});