define("hintguest/components/u-name-initials-comp", ["exports", "universe-gui/components/u-name-initials-comp"], function (_exports, _uNameInitialsComp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uNameInitialsComp.default;
    }
  });
});