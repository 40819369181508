define("hintguest/components/u-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "a",
    classNames: ["popover-wrapper"],

    /*
     * lifecycle
     */
    didRender: function didRender() {
      this.$().popover({
        container: this.get('popOverContainer') || null,
        placement: this.get('placement') || "top",
        trigger: this.get('popOverTrigger') || "hover",
        title: this.get('title') || "",
        content: this.get('content') || "",
        html: this.get('html') || false
      });
    }
  });

  _exports.default = _default;
});