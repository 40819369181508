define("hintguest/templates/get-chrome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fa1WPwjS",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"alert alert-warning\"],[9,\"role\",\"alert\"],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"fa fa-exclamation-triangle\"],[9,\"aria-hidden\",\"true\"],[7],[8],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"sr-only\"],[7],[1,[25,\"t\",[\"intl.error\"],null],false],[0,\":\"],[8],[1,[25,\"t\",[\"getChrome.message\"],null],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hintguest/templates/get-chrome.hbs"
    }
  });

  _exports.default = _default;
});