define("hintguest/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "about": {
      "contacts": "Contacts"
    },
    "account": {
      "personEditSubtitle": "Update your personal data.",
      "securityEditSubtitle": "Change your login information.",
      "settingsSubtitle": "Customize the system as you like.",
      "softwareUpdated": "There is an update available - {module} version: {version}.<br><b>Click here to update!</b>",
      "title": "Account"
    },
    "application": {
      "loading": "Loading"
    },
    "avatar": {
      "accessories": {
        "kurt": "Kurt",
        "prescription1": "Prescription 1",
        "prescription2": "Prescription 2",
        "round": "Round",
        "sunglasses": "Sunglasses",
        "title": "Accessories",
        "wayfarers": "Wayfarers"
      },
      "clothes": {
        "blazerShirt": "Blazer Shirt",
        "blazerSweater": "Blazer Sweater",
        "collarSweater": "Collar Sweater",
        "graphicShirt": "Graphic Shirt",
        "hoodie": "Hoodie",
        "overall": "Overall",
        "shirtCrewNeck": "Shirt Crew Neck",
        "shirtScoopNeck": "Shirt Scoop Neck",
        "shirtVNeck": "Shirt V Neck",
        "title": "Clothes"
      },
      "colorFabric": {
        "black": "Black",
        "blue1": "Blue 1",
        "blue2": "Blue 2",
        "blue3": "Blue 3",
        "gray1": "Gray 1",
        "gray2": "Gray 2",
        "heather": "Heather",
        "pastelBlue": "PastelBlue",
        "pastelGreen": "PastelGreen",
        "pastelOrange": "PastelOrange",
        "pastelRed": "PastelRed",
        "pastelYellow": "PastelYellow",
        "pink": "Pink",
        "red": "Red",
        "title": "Color Fabric",
        "white": "White"
      },
      "eyebrow": {
        "angry": "Angry",
        "angryNatural": "Angry Natural",
        "default": "Default",
        "defaultNatural": "Default Natural",
        "flatNatural": "Flat Natural",
        "raisedExcited": "Raised Excited",
        "raisedExcitedNatural": "Raised Excited Natural",
        "sadConcerned": "Sad Concerned",
        "sadConcernedNatural": "Sad Concerned Natural",
        "title": "Eyebrow",
        "unibrowNatural": "Unibrow Natural",
        "upDown": "Up Down",
        "upDownNatural": "Up Down Natural"
      },
      "eyes": {
        "close": "Close",
        "cry": "Cry",
        "default": "Default",
        "dizzy": "Dizzy",
        "eyeRoll": "Eye Roll",
        "happy": "Happy",
        "hearts": "Hearts",
        "side": "Side",
        "squint": "Squint",
        "surprised": "Surprised",
        "title": "Eyes",
        "wink": "Wink",
        "winkWacky": "Wink Wacky"
      },
      "hatColor": {
        "blue1": "Blue 1",
        "blue2": "Blue 2",
        "blue3": "Blue 3",
        "gray1": "Gray 1",
        "gray2": "Gray 2",
        "heather": "Heather",
        "pastelBlue": "PastelBlue",
        "pastelGreen": "PastelGreen",
        "pastelOrange": "PastelOrange",
        "pastelRed": "PastelRed",
        "pastelYellow": "PastelYellow",
        "pink": "Pink",
        "red": "Red",
        "title": "Hat Color",
        "white": "White"
      },
      "mouth": {
        "concerned": "Concerned",
        "default": "Default",
        "disbelief": "Disbelief",
        "eating": "Eating",
        "grimace": "Grimace",
        "sad": "Sad",
        "screamOpen": "Scream Open",
        "serious": "Serious",
        "smile": "Smile",
        "title": "Mouth",
        "tongue": "Tongue",
        "twinkle": "Twinkle",
        "vomit": "Vomit"
      },
      "skin": {
        "black": "Black",
        "brown": "Brown",
        "darkBrown": "Dark Brown",
        "light": "Light",
        "pale": "Pale",
        "tanned": "Tanned",
        "title": "Skin",
        "yellow": "Yellow"
      },
      "top": {
        "ShortHairShortFlat": "Short Hair Short Flat",
        "eyepatch": "Eyepatch",
        "hat": "Hat",
        "hijab": "Hijab",
        "longHairBigHair": "Long Hair Big Hair",
        "longHairBob": "Long Hair Bob",
        "longHairBun": "Long Hair Bun",
        "longHairCurly": "Long Hair Curly",
        "longHairCurvy": "Long Hair Curvy",
        "longHairDreads": "Long Hair Dreads",
        "longHairFrida": "Long Hair Frida",
        "longHairFro": "Long Hair Fro",
        "longHairFroBand": "Long Hair Fro Band",
        "longHairMiaWallace": "Long Hair Mia Wallace",
        "longHairNotTooLong": "Long Hair Not Too Long",
        "longHairShavedSides": "Long Hair Shaved Sides",
        "longHairStraight1": "Long Hair Straight 1",
        "longHairStraight2": "Long Hair Straight 2",
        "longHairStraightStrand": "Long Hair Straight Strand",
        "shortHairDreads1": "Short Hair Dreads 1",
        "shortHairDreads2": "Short Hair Dreads 2",
        "shortHairFrizzle": "Short Hair Frizzle",
        "shortHairShaggyMullet": "Short Hair Shaggy Mullet",
        "shortHairShortCurly": "Short Hair Short Curly",
        "shortHairShortRound": "Short Hair Short Round",
        "shortHairShortWaved": "Short Hair Short Waved",
        "shortHairSides": "Short Hair Sides",
        "shortHairTheCaesar": "Short Hair The Caesar",
        "shortHairTheCaesarSidePart": "Short Hair The Caesar Side Part",
        "title": "Top",
        "turban": "Turban",
        "winterHat1": "Winter Hat 1",
        "winterHat2": "Winter Hat 2",
        "winterHat3": "Winter Hat 3",
        "winterHat4": "Winter Hat 4"
      }
    },
    "bugReports": {
      "bugReport": {
        "message": "Message",
        "subject": "Subject",
        "title": "Bug report"
      },
      "title": "Bug reports"
    },
    "companies": {
      "details": {
        "disabled": "This company does not yet have an associated plan, to start a test plan please contact us by email!"
      },
      "new": {
        "title": "New Company"
      }
    },
    "crud": {
      "associate": "Associate",
      "cancel": "Cancel",
      "close": "Close",
      "create": "Create",
      "createAndAdd": "Create and Add New",
      "createSuccess": "Successful registration for",
      "created": "Created",
      "delete": "Remove",
      "details": "Details",
      "register": "Register",
      "registered": "Registered",
      "revert": "Revert",
      "save": "Save",
      "saved": "Saved",
      "schedule": "Schedule",
      "update": "Update",
      "updated": "Updated"
    },
    "dayWeek": {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
    },
    "enums": {
      "chronos": {
        "date": "date",
        "dateUpper": "Date",
        "day": "day",
        "dayUpper": "Day",
        "days": "days",
        "hour": "hour",
        "hourUpper": "Hour",
        "hours": "hours",
        "hoursUpper": "Hours",
        "month": "month",
        "monthUpper": "Month",
        "months": "months",
        "today": "today",
        "todayUpper": "Today",
        "waitingTime": "waiting time",
        "waitingTimeUpper": "Waiting Time",
        "week": "week",
        "weekUpper": "Week",
        "year": "year",
        "yearUpper": "Year",
        "years": "years"
      },
      "countries": {
        "AD": "Andorra",
        "AE": "United Arab Emirates",
        "AF": "Afghanistan",
        "AG": "Antigua and Barbuda",
        "AI": "Anguilla",
        "AL": "Albania",
        "AM": "Armenia",
        "AN": "Netherlands Antilles",
        "AO": "Angola",
        "AQ": "Antarctica",
        "AR": "Argentina",
        "AS": "American Samoa",
        "AT": "Austria",
        "AU": "Australia",
        "AW": "Aruba",
        "AZ": "Azerbaijan",
        "BA": "Bosnia and Herzegovina",
        "BB": "Barbados",
        "BD": "Bangladesh",
        "BE": "Belgium",
        "BF": "Burkina Faso",
        "BG": "Bulgaria",
        "BH": "Bahrain",
        "BI": "Burundi",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BN": "Brunei Darussalam",
        "BO": "Bolivia",
        "BR": "Brazil",
        "BS": "Bahamas",
        "BT": "Bhutan",
        "BV": "Bouvet Islands",
        "BW": "Botswana",
        "BY": "Belarus",
        "BZ": "Belize",
        "CA": "Canada",
        "CC": "Cocos (keeling) Islands",
        "CD": "Congo, The Democratic Republic of the",
        "CF": "Central African Republic",
        "CG": "Congo",
        "CH": "Switzerland",
        "CI": "Côte d’Ivoire",
        "CK": "Cook Islands",
        "CL": "Chile",
        "CM": "Cameroon",
        "CN": "China",
        "CO": "Colombia",
        "CR": "Costa Rica",
        "CU": "Cuba",
        "CV": "Cape Verde",
        "CX": "Christmas Island",
        "CY": "Cyprus",
        "CZ": "Czech Republic",
        "DE": "Germany",
        "DJ": "Djibouti",
        "DK": "Denmark",
        "DM": "Dominica",
        "DO": "Dominican Republic",
        "DZ": "Algeria",
        "EC": "Ecuador",
        "EE": "Estonia",
        "EG": "Egypt",
        "EH": "Western Sahara",
        "ER": "Eritrea",
        "ES": "Spain",
        "ET": "Ethiopia",
        "FI": "Finland",
        "FJ": "Fiji",
        "FK": "Falkand Islands (Malvinas)",
        "FM": "Micronesia, Federated States of",
        "FO": "Faroe Islands",
        "FR": "France",
        "GA": "Gabon",
        "GB": "United Kingdom",
        "GD": "Grenada",
        "GE": "Georgia",
        "GF": "French Guiana",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GL": "Greenland",
        "GM": "Gambia",
        "GN": "Guinea",
        "GP": "Guadeloupe",
        "GQ": "Equatorial Guinea",
        "GR": "Greece",
        "GS": "South Georgia and the South Sandwich Islands",
        "GT": "Guatemala",
        "GU": "Guam",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HK": "Hong Kong",
        "HM": "Heard Islands and McDonald Islands",
        "HN": "Honduras",
        "HR": "Croatia",
        "HT": "Haiti",
        "HU": "Hungary",
        "ID": "Indonesia",
        "IE": "Ireland",
        "IL": "Israel",
        "IN": "India",
        "IO": "British Indian Ocean Territory",
        "IQ": "Iraq",
        "IR": "Iran, Islamic Republic of",
        "IS": "Iceland",
        "IT": "Italy",
        "JM": "Jamaica",
        "JO": "Jordan",
        "JP": "Japan",
        "KE": "Kenya",
        "KG": "Kyrgyzstan",
        "KH": "Cambodia",
        "KI": "Kiribati",
        "KM": "Comoros",
        "KN": "Saint Kitts and Nevis",
        "KP": "Korea, Democratic People’s Republic of",
        "KR": "Korea, Republic of",
        "KW": "Kuwait",
        "KY": "Cayman Islands",
        "KZ": "Kazakstan",
        "LA": "Lao People’s Democratic Republic",
        "LB": "Lebanon",
        "LC": "Saint Lucia",
        "LI": "Liechtenstein",
        "LK": "Sri Lanka",
        "LR": "Liberia",
        "LS": "Lesotho",
        "LT": "Lithuania",
        "LU": "Luxembourg",
        "LV": "Latvia",
        "LY": "Libyan Arab Jamahiriya",
        "MA": "Morocco",
        "MC": "Monaco",
        "MD": "Moldova, Republic of",
        "MG": "Madagascar",
        "MH": "Marshall Islands",
        "MK": "Macedonia, The Former Yugoslav Republic of",
        "ML": "Mali",
        "MM": "Myanmar",
        "MN": "Mongolia",
        "MO": "Macau",
        "MP": "Northern Mariana Islands",
        "MQ": "Martinique",
        "MR": "Mauritania",
        "MS": "Montserrat",
        "MT": "Malta",
        "MU": "Mauritius",
        "MV": "Maldives",
        "MW": "Malawi",
        "MX": "Mexico",
        "MY": "Malaysia",
        "MZ": "Mozambique",
        "NA": "Namibia",
        "NC": "New Caledonia",
        "NE": "Niger",
        "NF": "Norfolk Islands",
        "NG": "Nigeria",
        "NI": "Nicaragua",
        "NL": "Netherlands",
        "NO": "Norway",
        "NP": "Nepal",
        "NR": "Nauru",
        "NU": "Niue",
        "NZ": "New Zealand",
        "OM": "Oman",
        "PA": "Panama",
        "PE": "Peru",
        "PF": "French Polynesia",
        "PG": "Papua New Guinea",
        "PH": "Philippines",
        "PK": "Pakistan",
        "PL": "Poland",
        "PM": "Saint Pierre and Miquelon",
        "PN": "Pitcairn",
        "PR": "Puerto Rico",
        "PS": "Palestinian Territory, Occupied",
        "PT": "Portugal",
        "PW": "Palau",
        "PY": "Paraguay",
        "QA": "Qatar",
        "RE": "Réunion",
        "RO": "Romania",
        "RU": "Russian Federation",
        "RW": "Rwanda",
        "SA": "Saudi Arabia",
        "SB": "Solomon Islands",
        "SC": "Seychelles",
        "SD": "Sudan",
        "SE": "Sweden",
        "SG": "Singapore",
        "SH": "Saint Helena",
        "SI": "Slovenia",
        "SJ": "Svalbard and Jan Mayen",
        "SK": "Slovakia",
        "SL": "Sierra Leone",
        "SM": "San Marino",
        "SN": "Senegal",
        "SO": "Somalia",
        "SR": "Suriname",
        "ST": "Sao Tome and Principe",
        "SV": "El Salvador",
        "SY": "Syrian Arab Republic",
        "SZ": "Swaziland",
        "TC": "Turks and Caicos Islands",
        "TD": "Chad",
        "TF": "French Southern Territories",
        "TG": "Togo",
        "TH": "Thailand",
        "TJ": "Tajikistan",
        "TK": "Tokelau",
        "TM": "Turkmenistan",
        "TN": "Tunisia",
        "TO": "Tonga",
        "TP": "East Timor",
        "TR": "Turkey",
        "TT": "Trinidad and Tobago",
        "TV": "Tuvalu",
        "TW": "Taiwan, Province of China",
        "TZ": "Tanzania, United Republic of",
        "UA": "Ukraine",
        "UG": "Uganda",
        "UM": "United States Minor Outlying Islands",
        "US": "United States",
        "UY": "Uruguay",
        "UZ": "Uzbekistan",
        "VA": "Holy See (Vatican City State)",
        "VC": "Saint Vincent and the Grenadines",
        "VE": "Venezuela",
        "VG": "Virgin Islands, British",
        "VI": "Virgin Islands, U. S.",
        "VN": "Viet Nam",
        "VU": "Vanuatu",
        "WF": "Wallis and Futuna",
        "WS": "Samoa",
        "YE": "Yemen",
        "YT": "Mayotte",
        "YU": "Yugoslavia",
        "ZA": "South Africa",
        "ZM": "Zambia",
        "ZW": "Zimbabwe"
      },
      "currencies": {
        "$": "Dollar",
        "EUR": "Euro",
        "GBP": "Pound",
        "R$": "Real"
      },
      "mobileCountries": {
        "ES": "Spain +354",
        "PT": "Portugal +351"
      },
      "sex": {
        "female": {
          "initial": "F",
          "name": "Female"
        },
        "male": {
          "initial": "M",
          "name": "Male"
        }
      }
    },
    "error": {
      "adapter": {
        "forbidden": "You don't have permissions to do this"
      },
      "noInternet": "We can't find a connection to the internet",
      "noPermission": "You don't have permissions to access this page!",
      "systemDown": "The system is currently down",
      "unexistingBreed": "You must select a breed from the list"
    },
    "footer": {
      "rights": "All rights reserved",
      "toggle": "Change view"
    },
    "form": {
      "administrative": "Administrative",
      "chooseOne": " -- choose one --",
      "extra": "Extra",
      "general": "General",
      "health": "Health",
      "look": "Look",
      "required": "This field is required"
    },
    "getChrome": {
      "message": "This application only works with Google Chrome."
    },
    "header": {
      "logout": "Logout"
    },
    "help": {
      "faq": {
        "last-rev-date": "29th of June 2016",
        "link": "FAQ",
        "title": "Frequently Asked Questions"
      },
      "manual": {
        "last-rev-date": "23rd of June 2016",
        "link": "Manual",
        "title": "Manual"
      },
      "title": "Help"
    },
    "icons": {
      "bug": "bug",
      "company": "building-o",
      "employee": "users",
      "image": "image",
      "user": "user",
      "worker": "user-md"
    },
    "index": {
      "addInternments": {
        "description": "Create or Search customer, fill the animal details and intern.",
        "title": "Internments"
      },
      "associateCollaborators": {
        "description": "Associate collaborators with differente roles.",
        "title": "Collaborators"
      },
      "comments": "If you have any doubts or comments please contact",
      "createAccount": {
        "description": "Fill in your details and register your account.",
        "title": "Create Account"
      },
      "createHospital": {
        "description": "Add the details of the hospital and create.",
        "title": "Register Hospital"
      },
      "welcome.subtitle": "by {corp}"
    },
    "intl": {
      "attention": "Attention!",
      "close": "Are you sure you want to close?",
      "discharge": "Are you sure you want to schedule a discharge?",
      "emptyString": "--",
      "error": "Error",
      "finish": "Are you sure you want to finish?",
      "information": "info",
      "my": "My",
      "noContent": "There are no",
      "or": "or",
      "pause": "Are you sure you want to pause the consultation?",
      "remove": "Are you sure you want to remove?",
      "revert": "Are you sure you want to revert?",
      "revertDischarge": "Are you sure you want to revert the discharge?",
      "showLess": "Show less...",
      "showMore": "Show more...",
      "unsavedChanges": "When cancelling all unsaved changes will be lost. Do you wish to continue?"
    },
    "language-select": {
      "language": {
        "en": "English",
        "es": "Español",
        "es-mx": "Español Mexicano",
        "pt": "Português",
        "pt-br": "Português do Brasil"
      }
    },
    "login": {
      "forgot": "Forgot password?",
      "invalidPassword": "Invalid password",
      "missingAccount": "Do not have an account?",
      "submit": "Login",
      "subtitle": "Logint into Hint Guest",
      "title": "Login",
      "unknownUser": "Unknown email"
    },
    "menu": {
      "new": "New"
    },
    "models": {
      "company": {
        "child": "Sub-unidade",
        "children": "Sub-unidades",
        "country": "Country",
        "email": "E-mail",
        "entity": "Company",
        "entityPlural": "Companies",
        "facebook": "Facebook",
        "logo": "Logo",
        "name": "Name",
        "notes": "Notes",
        "parent": "Parent",
        "phone": "Phone",
        "street": "Street",
        "town": "Town",
        "vatNumber": "VAT",
        "zip": "Zip Code"
      },
      "employee": {
        "entity": "Employee",
        "entityPlural": "Employees",
        "profile": "Perfil"
      },
      "person": {
        "country": "Country",
        "email": "Email",
        "entity": "Person",
        "entityPlural": "People",
        "mobilePhone": "Mobile",
        "name": "Name",
        "nif": "UTR",
        "phone": "Phone",
        "street": "Address",
        "town": "Town",
        "zip": "Zip Code"
      },
      "role": {
        "administrator": "Administrator",
        "employee": "Employee",
        "unknown": "Unknown role"
      },
      "user": {
        "email": "Email",
        "entity": "User",
        "entityPlural": "Users",
        "name": "Name",
        "nif": "UTR",
        "password": "Password"
      }
    },
    "modules": {
      "management": {
        "description": "The Perfect experience 4U",
        "title": "Restaurant hints"
      }
    },
    "navigation": {
      "addAsPatient": "Add as patient",
      "back": "Back",
      "confirm": "Confirm"
    },
    "organizations": {
      "new": {
        "title": "New Organization"
      },
      "organization": {
        "avatar": "Avatar",
        "avatars": "Avatars",
        "businessHours": "Business Hours",
        "children": "Children",
        "company": "Company",
        "country": "Country",
        "created": "Created",
        "details": {
          "disabled": "This organizaiton has no active plan."
        },
        "email": "Email",
        "enable": "Enable",
        "facebook": "Facebook",
        "index": {
          "afterNoon": "Good afternoon",
          "hi": "Hi",
          "morning": "Good morning",
          "night": "Good night"
        },
        "name": "Name",
        "notes": "Notes",
        "parent": "Parent",
        "phone": "Phone",
        "plan": "Plan",
        "street": "Street",
        "title": "Organization",
        "town": "Town",
        "zip": "Zip Code"
      },
      "title": "Organizations"
    },
    "pages": {
      "account": "Account",
      "companies": "Companies",
      "company": "Company",
      "edit": "Edit",
      "employees": "Employees",
      "new": "Create",
      "organizations": "Organizations",
      "recoverPassword": "Password recovery",
      "security": "Security",
      "settings": "Settings"
    },
    "restaurants": {
      "title": "Restaurants"
    },
    "systemDown1": "The system will be down for maintenance at",
    "systemDown2": "for approximately two hours.",
    "terms": {
      "cookies": {
        "link": "Cookies",
        "title": "Cookies"
      },
      "general": {
        "link": "Terms",
        "title": "Terms and Conditions"
      },
      "language-disclaimer": "This agreement was written in Portuguese (pt-PT). To the extent any translated version of this agreement conflicts with the Portuguese version, the Portuguese version controls",
      "last-rev-date": "1st of january 2016",
      "last-rev-date-desc": "Last revision date",
      "privacy": {
        "link": "Privacy",
        "title": "Privacy Policy"
      },
      "translation-not-available": "This page is not available in English, for now we only provide a version in Portuguese."
    },
    "travel": {
      "purpose": {
        "business": "business",
        "leasure": "leasure"
      }
    },
    "unexpectedError": "Unexpected error. Wait for a few minutes and try again. If the error persists please contact Pet Universal.",
    "user": {
      "edit": {
        "title": "User info"
      },
      "new": {
        "agree-terms": "By clicking Login, you agree to our terms and that you have read our privacy policy",
        "confirmPassword": "Confirm password",
        "email-example": "your.email@example.com",
        "name-example": "User Name",
        "password-example": "password",
        "passwordsDontMatch": "Passwords don't match",
        "title": "Sign-up",
        "validationEmailSent": "Thank you for registering, please go to your email and validate your account"
      },
      "recoverPassword.success": "An email was sent to you with your new password"
    }
  };
  _exports.default = _default;
});