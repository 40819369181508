define("hintguest/translations/pt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "about": {
      "contacts": "Contactos"
    },
    "account": {
      "personEditSubtitle": "Edite os seus dados pessoais.",
      "securityEditSubtitle": "Altere os seus dados de login.",
      "settingsSubtitle": "Personalize o sistema a seu gosto.",
      "softwareUpdated": "Está disponível uma atualização - {module} versão: {version}.<br><b>Clique aqui para actualizar!</b>",
      "title": "Conta"
    },
    "application": {
      "loading": "A carregar"
    },
    "avatar": {
      "accessories": {
        "kurt": "Kurt",
        "prescription1": "Prescrição 1",
        "prescription2": "Prescrição 2",
        "round": "Volta",
        "sunglasses": "Oculos de Sol",
        "title": "Acessórios",
        "wayfarers": "Wayfarers"
      },
      "clothes": {
        "blazerShirt": "Blazer Shirt",
        "blazerSweater": "Blazer Sweater",
        "collarSweater": "Camisola de Colarinho",
        "graphicShirt": "Camisa Gráfica",
        "hoodie": "Hoodie",
        "overall": "Jardineiras",
        "shirtCrewNeck": "Camisa Gola",
        "shirtScoopNeck": "Camisa de Pescoço",
        "shirtVNeck": "Camisa em V",
        "title": "Roupas"
      },
      "colorFabric": {
        "black": "Preto",
        "blue1": "Azul 1",
        "blue2": "Azul 2",
        "blue3": "Azul 3",
        "gray1": "Cinzento 1",
        "gray2": "Cinzento 2",
        "heather": "Urze",
        "pastelBlue": "Azul Pastel",
        "pastelGreen": "Verde Pastel",
        "pastelOrange": "Laranja Pastel",
        "pastelRed": "Vermelho Pastel",
        "pastelYellow": "Amarelo Pastel",
        "pink": "Rosa",
        "red": "Vermelho",
        "title": "Cores de Tecido",
        "white": "Branco"
      },
      "eyebrow": {
        "angry": "Irritado",
        "angryNatural": "Irritado Natural",
        "default": "Default",
        "defaultNatural": "Default Natural",
        "flatNatural": "Liso Natural",
        "raisedExcited": "Animado Levantado",
        "raisedExcitedNatural": "Animado Levantado Natural",
        "sadConcerned": "Triste",
        "sadConcernedNatural": "Trite Natural",
        "title": "Sobrancelha",
        "unibrowNatural": "Monosobrancelha",
        "upDown": "Cima Baixo",
        "upDownNatural": "Cima Baixo Natural"
      },
      "eyes": {
        "close": "Fechados",
        "cry": "Chorar",
        "default": "Default",
        "dizzy": "Tonto",
        "eyeRoll": "Rolo de Olhos",
        "happy": "Feliz",
        "hearts": "Corações",
        "side": "Lado",
        "squint": "Estrabismo",
        "surprised": "Surpreso",
        "title": "Olhos",
        "wink": "Piscadela",
        "winkWacky": "Piscadela Maluca"
      },
      "hatColor": {
        "blue1": "Azul 1",
        "blue2": "Azul 2",
        "blue3": "Azul 3",
        "gray1": "Cinzento 1",
        "gray2": "Cinzento 2",
        "heather": "Urze",
        "pastelBlue": "Azul Pastel",
        "pastelGreen": "Verde Pastel",
        "pastelOrange": "Laranja Pastel",
        "pastelRed": "Vermelho Pastel",
        "pastelYellow": "Amarelo Pastel",
        "pink": "Rosa",
        "red": "Vermelho",
        "title": "Cor do Chapéu",
        "white": "Branco"
      },
      "mouth": {
        "concerned": "Preocupado",
        "default": "Default",
        "disbelief": "Descrença",
        "eating": "A comer",
        "grimace": "Careta",
        "sad": "Triste",
        "screamOpen": "Medo",
        "serious": "Sisudo",
        "smile": "Sorriso",
        "title": "Boca",
        "tongue": "Língua",
        "twinkle": "Cintilação",
        "vomit": "Vomito"
      },
      "skin": {
        "black": "Preto",
        "brown": "Castanho",
        "darkBrown": "Castanho Escuro",
        "light": "Mabro",
        "pale": "Pálido",
        "tanned": "Bronzeada",
        "title": "Pele",
        "yellow": "Amarela"
      },
      "top": {
        "ShortHairShortFlat": "Cabelo Curto Liso",
        "eyepatch": "Tapa-Olho",
        "hat": "Chapéu",
        "hijab": "Hijab",
        "longHairBigHair": "Cabelo Comprido",
        "longHairBob": "Cabelo Comprido Bob",
        "longHairBun": "Bolo De Cabelo Longo",
        "longHairCurly": "Cabelo Comprido Encaracolado",
        "longHairCurvy": "Cabelo Comprido Curvilínea",
        "longHairDreads": "Cabelo Comprido Dread",
        "longHairFrida": "Cabelo Comprido Frida",
        "longHairFro": "Cabelo Comprido Afro",
        "longHairFroBand": "Cabelo Comprido para Banda",
        "longHairMiaWallace": "Cabelo Comprido Mia Wallace",
        "longHairNotTooLong": "Cabelo Comprido Não Demais",
        "longHairShavedSides": "Cabelo Comprido e Lados Raspados",
        "longHairStraight1": "Cabelo Comprido em Linha Reta 1",
        "longHairStraight2": "Cabelo Comprido em Linha Reta 2",
        "longHairStraightStrand": "Cabelo Comprido em Linha Reta Strand",
        "shortHairDreads1": "Cabelo Curto Dreads 1",
        "shortHairDreads2": "Cabelo Curto Dreads 2",
        "shortHairFrizzle": "Cabelo Curto Frizzle",
        "shortHairShaggyMullet": "Cabelo Curto Desgrenhado",
        "shortHairShortCurly": "Cabelo Curto Curto Encaracolado",
        "shortHairShortRound": "Cabelo Curto",
        "shortHairShortWaved": "Cabelo Curto Ondulado",
        "shortHairSides": "Cabelo Curto dos Lados",
        "shortHairTheCaesar": "Cabelo Curto Caesar",
        "shortHairTheCaesarSidePart": "Cabelo Curto Caesar Parte Lateral",
        "title": "Cabeça",
        "turban": "Turbante",
        "winterHat1": "Chapéu de Inverno 1",
        "winterHat2": "Chapéu de Inverno 2",
        "winterHat3": "Chapéu de Inverno 3",
        "winterHat4": "Chapéu de Inverno 4"
      }
    },
    "bugReports": {
      "bugReport": {
        "message": "Mensagem",
        "subject": "Assunto",
        "title": "Reportar erro"
      },
      "title": "Reportar erros"
    },
    "companies": {
      "details": {
        "disabled": "Esta entidade não tem um plano associado. Fale connosco para activar um tarifário de testes!"
      },
      "new": {
        "title": "Nova Empresa"
      }
    },
    "crud": {
      "associate": "Associar",
      "cancel": "Cancelar",
      "close": "Fechar",
      "create": "Criar",
      "createAndAdd": "Guardar e Criar Nova",
      "createSuccess": "Registo bem sucedido para",
      "created": "Criada",
      "delete": "Remover",
      "details": "Detalhes",
      "register": "Registar",
      "registered": "Registado",
      "revert": "Reverter",
      "save": "Gravar",
      "saved": "Gravado",
      "schedule": "Agendar",
      "update": "Editar",
      "updated": "Editado"
    },
    "dayWeek": {
      "0": "Domingo",
      "1": "Segunda",
      "2": "Terça",
      "3": "Quarta",
      "4": "Quinta",
      "5": "Sexta",
      "6": "Sábado"
    },
    "enums": {
      "chronos": {
        "date": "data",
        "dateUpper": "Data",
        "day": "dia",
        "dayUpper": "Dia",
        "days": "dias",
        "hour": "hora",
        "hourUpper": "Hora",
        "hours": "horas",
        "hoursUpper": "Horas",
        "month": "mês",
        "monthUpper": "Mês",
        "months": "meses",
        "today": "hoje",
        "todayUpper": "Hoje",
        "waitingTime": "tempo de espera",
        "waitingTimeUpper": "Tempo de Espera",
        "week": "semana",
        "weekUpper": "Semana",
        "year": "ano",
        "yearUpper": "Ano",
        "years": "anos"
      },
      "countries": {
        "AD": "Andorra",
        "AE": "Emiratos Árabes Unidos",
        "AF": "Afeganistão",
        "AG": "Antígua e Barbuda",
        "AI": "Anguila",
        "AL": "Albânia",
        "AM": "Arménia",
        "AN": "Antilhas Holandesas",
        "AO": "Angola",
        "AQ": "Antárctica",
        "AR": "Argentina",
        "AS": "Samoa Americana",
        "AT": "Áustria",
        "AU": "Austrália",
        "AW": "Aruba",
        "AZ": "Azerbaijão",
        "BA": "Bósnia e Herzegovina",
        "BB": "Barbados",
        "BD": "Bangladesh",
        "BE": "Bélgica",
        "BF": "Burkina Faso",
        "BG": "Bulgária",
        "BH": "Barém",
        "BI": "Burundi",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BN": "Brunei Darussalam",
        "BO": "Bolívia",
        "BR": "Brasil",
        "BS": "Bahamas",
        "BT": "Butão",
        "BV": "Ilhas Bouvet",
        "BW": "Botswana",
        "BY": "Bielorrússia",
        "BZ": "Belize",
        "CA": "Canadá",
        "CC": "Ilhas Cocos (Keeling)",
        "CD": "Congo (República Democrático do)",
        "CF": "Centro-Africana (República)",
        "CG": "Congo",
        "CH": "Suíça",
        "CI": "Costa do Marfim",
        "CK": "Ilhas Cook",
        "CL": "Chile",
        "CM": "Camarões",
        "CN": "China",
        "CO": "Colômbia",
        "CR": "Costa Rica",
        "CU": "Cuba",
        "CV": "Cabo Verde",
        "CX": "Ilhas Christmas",
        "CY": "Chipre",
        "CZ": "República Checa",
        "DE": "Alemanha",
        "DJ": "Jibuti",
        "DK": "Dinamarca",
        "DM": "Domínica",
        "DO": "República Dominicana",
        "DZ": "Argélia",
        "EC": "Equador",
        "EE": "Estónia",
        "EG": "Egipto",
        "EH": "Sara Ocidental",
        "ER": "Eritreia",
        "ES": "Espanha",
        "ET": "Etiópia",
        "FI": "Finlândia",
        "FJ": "Ilhas Fiji",
        "FK": "Ilhas Falkland (Malvinas)",
        "FM": "Micronésia (Estados Federados da)",
        "FO": "Ilhas Faroé",
        "FR": "França",
        "GA": "Gabão",
        "GB": "Reino Unido",
        "GD": "Granada",
        "GE": "Geórgia",
        "GF": "Guiana Francesa",
        "GH": "Gana",
        "GI": "Gibraltar",
        "GL": "Gronelândia",
        "GM": "Gâmbia",
        "GN": "Guiné",
        "GP": "Guadalupe",
        "GQ": "Guiné Equatorial",
        "GR": "Grécia",
        "GS": "Geórgia do Sul e Ilhas Sandwich",
        "GT": "Guatemala",
        "GU": "Guam",
        "GW": "Guiné-Bissau",
        "GY": "Guiana",
        "HK": "Hong Kong",
        "HM": "Ilhas Heard e Ilhas McDonald",
        "HN": "Honduras",
        "HR": "Croácia",
        "HT": "Haiti",
        "HU": "Hungria",
        "ID": "Indonésia",
        "IE": "Irlanda",
        "IL": "Israel",
        "IN": "Índia",
        "IO": "Território Britânico do Oceano Índico",
        "IQ": "Iraque",
        "IR": "Irão (República Islâmica)",
        "IS": "Islândia",
        "IT": "Itália",
        "JM": "Jamaica",
        "JO": "Jordânia",
        "JP": "Japão",
        "KE": "Kenya",
        "KG": "Quirguizistão",
        "KH": "Camboja",
        "KI": "Kiribati",
        "KM": "Comores",
        "KN": "São Cristóvão e Nevis",
        "KP": "Coreia (República Popular Democrática da)",
        "KR": "Coreia (República da)",
        "KW": "Kuwait",
        "KY": "Ilhas Caimão",
        "KZ": "Cazaquistão",
        "LA": "Laos (República Popular Democrática do)",
        "LB": "Líbano",
        "LC": "Santa Lúcia",
        "LI": "Liechtenstein",
        "LK": "Sri Lanka",
        "LR": "Libéria",
        "LS": "Lesoto",
        "LT": "Lituânia",
        "LU": "Luxemburgo",
        "LV": "Letónia",
        "LY": "Líbia (Jamahiriya Árabe da)",
        "MA": "Marrocos",
        "MC": "Mónaco",
        "MD": "Moldova (República de)",
        "MG": "Madagáscar",
        "MH": "Ilhas Marshall",
        "MK": "Macedónia (Antiga República Jugoslava da)",
        "ML": "Mali",
        "MM": "Myanmar",
        "MN": "Mongólia",
        "MO": "Macau",
        "MP": "Ilhas Marianas do Norte",
        "MQ": "Martinica",
        "MR": "Mauritânia",
        "MS": "Monserrate",
        "MT": "Malta",
        "MU": "Maurícias",
        "MV": "Maldivas",
        "MW": "Malawi",
        "MX": "México",
        "MY": "Malásia",
        "MZ": "Moçambique",
        "NA": "Namíbia",
        "NC": "Nova Caledónia",
        "NE": "Niger",
        "NF": "Ilhas Norfolk",
        "NG": "Nigéria",
        "NI": "Nicarágua",
        "NL": "Países Baixos",
        "NO": "Noruega",
        "NP": "Nepal",
        "NR": "Nauru",
        "NU": "Niue",
        "NZ": "Nova Zelândia",
        "OM": "Omã",
        "PA": "Panamá",
        "PE": "Peru",
        "PF": "Polinésia Francesa",
        "PG": "Papuásia-Nova Guiné",
        "PH": "Filipinas",
        "PK": "Paquistão",
        "PL": "Polónia",
        "PM": "São Pedro e Miquelon",
        "PN": "Pitcairn",
        "PR": "Porto Rico",
        "PS": "Território Palestiniano Ocupado",
        "PT": "Portugal",
        "PW": "Palau",
        "PY": "Paraguai",
        "QA": "Catar",
        "RE": "Reunião",
        "RO": "Roménia",
        "RU": "Rússia (Federação da)",
        "RW": "Ruanda",
        "SA": "Arábia Saudita",
        "SB": "Ilhas Salomão",
        "SC": "Seychelles",
        "SD": "Sudão",
        "SE": "Suécia",
        "SG": "Singapura",
        "SH": "Santa Helena",
        "SI": "Eslovénia",
        "SJ": "Svålbard e a Ilha de Jan Mayen",
        "SK": "Eslovaca (República)",
        "SL": "Serra Leoa",
        "SM": "São Marino",
        "SN": "Senegal",
        "SO": "Somália",
        "SR": "Suriname",
        "ST": "São Tomé e Príncipe",
        "SV": "El Salvador",
        "SY": "Síria (República Árabe da)",
        "SZ": "Suazilândia",
        "TC": "Turcos e Caicos (Ilhas)",
        "TD": "Chade",
        "TF": "Territórios Franceses do Sul",
        "TG": "Togo",
        "TH": "Tailândia",
        "TJ": "Tajiquistão",
        "TK": "Tokelau",
        "TM": "Turquemenistão",
        "TN": "Tunísia",
        "TO": "Tonga",
        "TP": "Timor Leste",
        "TR": "Turquia",
        "TT": "Trindade e Tobago",
        "TV": "Tuvalu",
        "TW": "Taiwan (Província da China)",
        "TZ": "Tanzânia, República Unida da",
        "UA": "Ucrânia",
        "UG": "Uganda",
        "UM": "Ilhas Menores Distantes dos Estados Unidos",
        "US": "Estados Unidos",
        "UY": "Uruguai",
        "UZ": "Usbequistão",
        "VA": "Santa Sé (Cidade Estado do Vaticano)",
        "VC": "São Vicente e Granadinas",
        "VE": "Venezuela",
        "VG": "Ilhas Virgens (britânicas)",
        "VI": "Ilhas Virgens (Estados Unidos)",
        "VN": "Vietname",
        "VU": "Vanuatu",
        "WF": "Wallis e Futuna (Ilha)",
        "WS": "Samoa",
        "YE": "Iémen",
        "YT": "Mayotte",
        "YU": "Jugoslávia",
        "ZA": "África do Sul",
        "ZM": "Zâmbia",
        "ZW": "Zimbabwe"
      },
      "currencies": {
        "$": "Dollar",
        "EUR": "Euro",
        "GBP": "Libra",
        "R$": "Real"
      },
      "mobileCountries": {
        "ES": "Espanha +354",
        "PT": "Portugal +351"
      },
      "sex": {
        "female": {
          "initial": "F",
          "name": "Feminino"
        },
        "male": {
          "initial": "M",
          "name": "Masculino"
        }
      }
    },
    "error": {
      "adapter": {
        "forbidden": "Não tem permissões para realizar esta acção"
      },
      "noInternet": "Não conseguimos encontrar uma ligação à internet",
      "noPermission": "Você não tem permissão para aceder a esta página!",
      "systemDown": "O sistema encontra-se em baixo",
      "unexistingBreed": "Tem que selecionar uma raça da lista"
    },
    "footer": {
      "rights": "Todos os direitos reservados",
      "toggle": "Alternar vista"
    },
    "form": {
      "administrative": "Administração",
      "chooseOne": " -- escolha um --",
      "extra": "Extra",
      "general": "Geral",
      "health": "Saúde",
      "look": "Aspeto",
      "required": "Este campo é obrigatório"
    },
    "getChrome": {
      "message": "Esta aplicação apenas funciona com o Google Chrome."
    },
    "header": {
      "logout": "Sair"
    },
    "help": {
      "faq": {
        "last-rev-date": "29 de Junho 2016",
        "link": "FAQ",
        "title": "Perguntas Frequentes"
      },
      "manual": {
        "last-rev-date": "23 de Junho 2016",
        "link": "Manual",
        "title": "Manual"
      },
      "title": "Ajuda"
    },
    "icons": {
      "bug": "bug",
      "company": "building-o",
      "employee": "users",
      "image": "image",
      "user": "user",
      "worker": "user-md"
    },
    "index": {
      "addInternments": {
        "description": "Criar ou Pesquisar cliente, preencher os dados do animal e internar.",
        "title": "Internamentos"
      },
      "associateCollaborators": {
        "description": "Associar colaboradores com diferentes funções.",
        "title": "Colaboradores"
      },
      "comments": "Em caso de dúvidas ou se tiver algum comentário, por favor contacte-nos",
      "createAccount": {
        "description": "Preencher os seus dados e fazer o registo da conta.",
        "title": "Criar Conta"
      },
      "createHospital": {
        "description": "Adicionar os dados do hospital e criar.",
        "title": "Registar Hospital"
      },
      "welcome.subtitle": "by {corp}"
    },
    "intl": {
      "attention": "Atenção!",
      "close": "Tem a certeza que pretende fechar?",
      "discharge": "Tem a certeza que pretende agendar a alta?",
      "emptyString": "--",
      "error": "Erro",
      "finish": "Tem a certeza que pretende terminar?",
      "information": "info",
      "my": "Meus",
      "noContent": "Não há",
      "or": "ou",
      "pause": "Tem a certeza que pretende pausar a consulta?",
      "remove": "Tem a certeza que pretende remover?",
      "revert": "Tem a certeza que pretende reverter?",
      "revertDischarge": "Tem a certeza que pretende reverter a alta?",
      "showLess": "Mostrar menos...",
      "showMore": "Mostrar mais...",
      "unsavedChanges": "Ao cancelar todas as alterações serão perdidas. Pretende continuar?"
    },
    "language-select": {
      "language": {
        "en": "English",
        "es": "Español",
        "es-mx": "Español Mexicano",
        "pt": "Português",
        "pt-br": "Português do Brasil"
      }
    },
    "login": {
      "forgot": "Esqueceu-se da sua password?",
      "invalidPassword": "Password errada",
      "missingAccount": "Ainda não tem uma conta?",
      "submit": "Entrar",
      "subtitle": "Entrar",
      "title": "Entrar",
      "unknownUser": "Email desconhecido"
    },
    "menu": {
      "new": "Novo"
    },
    "models": {
      "company": {
        "child": "Sub-unidade",
        "children": "Sub-unidades",
        "country": "País",
        "email": "E-mail",
        "entity": "Empresa",
        "entityPlural": "Empresas",
        "facebook": "Facebook",
        "logo": "Logo",
        "name": "Nome Fiscal",
        "notes": "Recados",
        "parent": "Unidade mãe",
        "phone": "Telefone",
        "street": "Morada fiscal",
        "town": "Cidade",
        "vatNumber": "NIF",
        "zip": "Cod. Postal"
      },
      "employee": {
        "entity": "Funcinário",
        "entityPlural": "Funcionários",
        "profile": "Perfil"
      },
      "person": {
        "country": "País",
        "email": "Email",
        "entity": "Pessoa",
        "entityPlural": "Pessoas",
        "mobilePhone": "Telemóvel",
        "name": "Nome",
        "nif": "NIF",
        "phone": "Telefone",
        "street": "Morada",
        "town": "Cidade",
        "zip": "Código Postal"
      },
      "role": {
        "administrator": "Adminstrador",
        "employee": "Funcionário",
        "unknown": "Perfil desconhecido"
      },
      "user": {
        "email": "Email",
        "entity": "Utilizador",
        "entityPlural": "Utilizadores",
        "name": "Nome",
        "nif": "NIF",
        "password": "Password"
      }
    },
    "navigation": {
      "addAsPatient": "Adicionar paciente",
      "back": "Voltar",
      "confirm": "Confirmar"
    },
    "organizations": {
      "new": {
        "title": "Nova Organização"
      },
      "organization": {
        "avatar": "Avatar",
        "avatars": "Avatars",
        "businessHours": "Horário",
        "children": "Sub-unidades",
        "company": "Empresa",
        "country": "País",
        "created": "Criado",
        "details": {
          "disabled": "Esta entidade não tem um plano associado."
        },
        "email": "Email",
        "enable": "Autorizar",
        "facebook": "Facebook",
        "index": {
          "afterNoon": "Boa Tarde",
          "hi": "Olá",
          "morning": "Bom dia",
          "night": "Boa Noite"
        },
        "name": "Nome",
        "notes": "Notas",
        "parent": "Unidade Mãe",
        "phone": "Telefone",
        "plan": "Plano",
        "street": "Morada",
        "title": "CAMV",
        "town": "Cidade",
        "zip": "Cod. Postal"
      },
      "title": "Organizações"
    },
    "pages": {
      "account": "Conta",
      "companies": "Empresas",
      "company": "Empresa",
      "edit": "Editar",
      "employees": "Funcionários",
      "new": "Criar",
      "organizations": "Organizations",
      "recoverPassword": "Recuperação de password",
      "security": "Segurança",
      "settings": "Configurações"
    },
    "systemDown1": "O sistema será desligado para manutenção às",
    "systemDown2": "durante aproximadamente duas horas.",
    "terms": {
      "cookies": {
        "link": "Cookies",
        "title": "Cookies"
      },
      "general": {
        "link": "Condições",
        "title": "Termos e condições Gerais"
      },
      "language-disclaimer": "",
      "last-rev-date": "01 de janeiro 2016",
      "last-rev-date-desc": "Data da última revisão",
      "privacy": {
        "link": "Privacidade",
        "title": "Política de Privacidade"
      },
      "translation-not-available": ""
    },
    "unexpectedError": "Ocorreu um erro inesperado.\nVolta a tentar novamente dentro de alguns instantes.\nSe o erro persistir por favor avise-nos.",
    "user": {
      "edit": {
        "title": "Dados Pessoais"
      },
      "new": {
        "agree-terms": "Ao clicar no botão, aceita os nossos termos e condições e confirma que leu a nossa política de privacidade",
        "confirmPassword": "Confirmar password",
        "email-example": "email@example.com",
        "name-example": "Nome de Utilizador",
        "password-example": "password",
        "passwordsDontMatch": "As senhas não são iguais",
        "title": "Registar",
        "validationEmailSent": "Obrigado por se registar, por favor vá ao seu email e valide a sua conta"
      },
      "recoverPassword.success": "Foi-lhe enviado um email com a sua nova password"
    }
  };
  _exports.default = _default;
});