define("hintguest/components/u-modal-new", ["exports", "universe-gui/components/u-modal-new"], function (_exports, _uModalNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uModalNew.default;
    }
  });
});