define("hintguest/routes/application", ["exports", "hintguest/config/environment", "universe-gui/routes/application"], function (_exports, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    intl: Ember.inject.service(),
    breadCrumb: null,
    beforeModel: function beforeModel() {
      this._super();

      this.get("intl").setLocale(['en']);
      $.ajax({
        type: "GET",
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/setup"
      });
    }
  });

  _exports.default = _default;
});